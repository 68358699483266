import React from 'react';
import Logo from '../assets/logo.svg';
import pointerDown from '../assets/pointer.svg';
import pointerRight from "../assets/pointerright.svg";
import curve from "../assets/curve.svg";
import rotimi from "../assets/about.png"
import leaf from "../assets/leaf.svg";
import {motion,useViewportScroll,useTransform} from "framer-motion";
import { Link as ScrollLink} from "react-scroll";
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
const Home = () => {
    const {scrollYProgress} = useViewportScroll();
    const scale = useTransform(scrollYProgress,[0,1],[1.2,.9])
    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    const date_ = Date.now();
    return (
        <>
            <header className="hero">
                <nav className="top-nav">
                    <figure>
                        <img alt="logo" className="logo" title="kereksuk logo" src={Logo}/>

                    </figure>
                    <Link to={'/obj#footer'} className="btn btn-dark"> Reach us</Link>
                </nav>

                <motion.div initial={{x:100}} animate={{x:0}} transition={transition} className="hero-center">
                    <h2 className="lgText">Growth. <span className="leaf">
               <motion.img initial={{rotate:15}} animate={{rotate:0}} className={'leafIcon'} transition={{delay:.6,...transition}} src={leaf} alt="leaf"/>
           </span></h2>
                </motion.div>
                <nav className="bottom-nav">
                    <div className="datebox">
                        <p className="dateboxMonth"><Moment format={'MMM'}>{date_}</Moment></p>
                        <h3 className="dateboxDay nowhitespace"><Moment format={'DD'}>{date_}</Moment> / <span className="dateboxSubDay"><Moment format={'YY'}>{date_}</Moment></span></h3>
                    </div>
                    <ul className="nav">
                        <li className="nav-item">
                            <Link to={'/blog'} className={'shadowText'}>Blog</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/story'} className={'shadowText'}>Our Story</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/gallery'} className={'shadowText'}>Gallery</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/yorfan'} className={'shadowText'} >YORFAN</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/faqs'} className={'shadowText'}>FAQs</Link>
                        </li>
                    </ul>
                </nav>
                <div className="nextBtn">
                    <motion.span  >
                        <ScrollLink
                            to="last"
                            smooth={true}
                            duration={350}
                        >
                            <motion.img  whileHover={{scale:1.1,y:20}} transition={transition} alt="pointer down" src={pointerDown} className="nextBtnIcon"/>

                        </ScrollLink>
                    </motion.span>
                </div>
            </header>

            <main className="about" id="last">
                <div className="center-all">
                    <div className="aboutTitleSection">
                        <div className="aboutTitleSectionCenter">
                            <div className="centerItemsAbs">
                                <div className="grid ">
                                    <motion.div
                                        style={{scale}}
                                        className="grid-item2_4">
                                        <motion.h6 className="h6">Founder / CEO Kereksuk Farms</motion.h6>
                                        <motion.h1 className="h4">ROTIMI</motion.h1>
                                        <motion.h1 className="h1">WILLIAMS</motion.h1>
                                        <motion.img style={{scale}} src={curve} className="curve" alt="curve"/>

                                    </motion.div>
                                    <div className="grid-item2_4 trY4">
                                        <motion.img style={{scale}} alt="Rotimi Williams" title="Rotimi Williams" src={rotimi}/>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="quote grid">
                        <div className="grid-item2_4">
                            <h6 className="h4">"Express Yourself to The
                                Fullest, it is your <span className=" greenText">greatest</span> weapon.”</h6>
                        </div>
                    </div>
                    <div className="aboutParagraph grid ">
                        <div className="grid-item2_4">
                            <p className="h6 textJust">Founded in 2012, we are a privately-owned rice production company in Africa, with farms in Nigeria and The Gambia and a rapid expansion plan into 3 other African countries. As the second largest paddy producer in Nigeria, our farmlands span over 45,000 hectares and we contribute to solving the problem of limited supply of paddy in Africa. Kereksuk consists of a team of vibrant, gender diverse and highly skilled professionals in the agricultural sector. </p>
                        </div>
                        <div className="grid-item2_4">
                            <p className="h6 textJust">A self-taught and self-made farmer, Rotimi Williams is the founder and CEO of kereksuk rice farms and is passionate about contributing to ending world hunger. Starting off with a banking background, Rotimi quit the financial sector with the sole aim of improving the agricultural sector and contributing to food security.
                            </p>
                        </div>
                    </div>
                    <Link to={'/story'} className="nextPage">
                        <img alt="pointer down" src={pointerRight} className="nextBtnIcon"/>
                    </Link>
                </div>
            </main>
        </>
    );
};

export default Home;