import React, {useCallback, useEffect, useState} from 'react';
import Navigation from "../../components/Navigation";
import '../../sass/components/gallery.scss';
import {BiPlay} from "react-icons/bi";
import AxiosInstance from "../../helpers/axiosInstance";
import Modal from "../../components/Modal";
import Moment from "react-moment";
import {AnimatePresence, motion} from "framer-motion";
import useScrollToTop from "../../helpers/UI/scrollToTop";

const Gallery = () => {

    useScrollToTop();
    const [images,setImages] = useState([])
    const [scaleImage,setScaleImage] = useState('')
    const [showImage,setShowImage] = useState(false)
    const [selectedId,setSelectedId] = useState(null)

    const [vids,setVids] = useState([])
    const [scaleVids,setScaleVids] = useState('')
    const [showVids,setShowVids] = useState(false)
    const  getAllImages = useCallback(() =>{
        AxiosInstance.get(`/g/all/image`)
            .then(res=>{
                setImages(res.data.data)
            })
            .catch(err=>{
                console.log(err)
            })
    },[setImages]);
    const  getAllVideos = useCallback(() =>{
        AxiosInstance.get(`/g/all/video`)
            .then(res=>{
                setVids(res.data.data)
            })
            .catch(err=>{
                console.log(err)
            })
    },[setVids]);
    useEffect(()=>{
        getAllImages();
    },[getAllImages])
    useEffect(()=>{

        getAllVideos();
    },[getAllVideos])
    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    const container = {
        hidden:{
            opacity:1,scale:0
        },
        visible:{
            opacity:1,
            duration: 1,
            scale:1,
            transition:{
                delayChildren:0.3,
                staggerChildren:0.2
            }
        }
    }
    const item = {
        hidden:{
            y:20,opacity:0
        },
        visible:{
            y:0,
            opacity:1
        }
    }
    return (
        <div className={'overflowHidden'} >

            <Navigation/>

            <AnimatePresence>

                {
                    showImage? <Modal body={'width80'} extra={selectedId} closeModal={setShowImage}>
                       <motion.div transition={transition} layoutId={selectedId}>
                           <motion.img  alt={''} className={'fluidImg'} src={scaleImage}/>
                       </motion.div>
                    </Modal>:null
                }
                {
                    showVids? <Modal body={'width60'} closeModal={setShowVids}>
                        <motion transition={transition} layoutId={selectedId} className="div">
                            <motion.video transition={{delay:.5,...transition}} controls autoPlay className={'fluidImg'} src={scaleVids}/></motion>
                    </Modal>:null
                }
            </AnimatePresence>

            <div className="greyBox Gcenter-all mgT8">
                <h4 className="h4">Gallery</h4>
                <p className="h5 mgT2">Explore Kereksuk farms </p>

                <motion.div className="mgT4 gallery_img_box"  variants={container}
                     initial={'hidden'}
                     animate={'visible'}>
                    {
                        images.length>0?images.map((im)=>{
                            return im?.files.map((img,i)=>{
                                return <motion.div variants={item} layoutId={im._id + i}  key={im._id + i } onClick={()=>{setScaleImage(`https://kereksuk.fra1.digitaloceanspaces.com/${img}`);
                                setShowImage(true);
                                setSelectedId(im._id + i);
                                }} className="gallery_img_box-item" style={{backgroundImage: `url(https://kereksuk.fra1.digitaloceanspaces.com/${img})`}}>
                                    <motion.div className="gallery_img_box-item-desc">
                                        <motion.h6 className="h6  whiteText">{im.title}</motion.h6>
                                        <motion.h6 className="h6  whiteText mgT1">{im?.description}</motion.h6>
                                        <motion.h6 className="h7 mgT2 whiteText"><Moment format={"MMM DD, YYYY"}>{im?.createdAt}</Moment></motion.h6>
                                </motion.div>

                                </motion.div>
                            });
                        }):null
                    }

                </motion.div>
                <h4 className="h4 mgT8">Video Gallery</h4>
                <div className="vid_box mgT2" style={{height:'40rem'}}>
                    <iframe width={"100%"} height={"100%"} src="https://www.youtube.com/embed/ixmy6a8fW_8"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4 className="h5 mgT8">Other Videos</h4>
                <div className="galleryVideos mgT4">


                    {
                        vids.length>0?vids.map((im)=>{
                            return im?.files.map((img,i)=>{
                                return  <>
                                    <motion.div layoutId={im._id + i} key={im._id + i } className="galleryVideo" onClick={()=>{setScaleVids(`https://kereksuk.fra1.digitaloceanspaces.com/${img}`);
                                        setShowVids(true);

                                        setSelectedId(im._id + i);
                                    }}>
                        <motion.span className="galleryVideo-play">
                            <BiPlay color={"#79E76C"} size={48}/>
                        </motion.span>
                                        <motion.video className={'fluidImg'} src={`https://kereksuk.fra1.digitaloceanspaces.com/${img}`}/>
                                        <motion.div className="galleryVideo-bottom">
                                            <motion.h6 className="h7 galleryVideo-h mgT1"><Moment format={"MMM DD, YYYY"}>{im.createdAt}</Moment></motion.h6>
                                            <motion.p className="h5 whiteText mgT1">{im.title}</motion.p>
                                        </motion.div>
                                    </motion.div>

                                </>
                            });
                        }):<>

                        <div className="greyCard">
                            <h6 className="h6">No Videos Yet</h6>
                        </div></>
                    }
                </div>
            </div>

        </div>
    );
};

export default Gallery;