import React,{useContext} from 'react';
import {Link} from 'react-router-dom';
import {BiListUl,BiPlus,BiVideo,BiGridAlt} from 'react-icons/bi';
import {FiSettings,FiUsers,FiLogOut} from 'react-icons/fi';
import '../sass/layout/admin.scss'
import Logo from "../assets/logo.svg";
import {UserProvider} from '../hoc/userProvider';

const AdminDash = (props) => {
    const ctx = useContext(UserProvider);
    return (
        <div className="dashboard">
            <div className="dashboard_top">
            <figure>
                <Link to={'/'}><img alt="logo" className="logo" title="kereksuk logo" src={Logo}/></Link>

            </figure>
                <ul className={'dashboard_top-list'}>

                    <li className="dashboard_top-list-item">
                        <Link to={'/'} className={'dashboard_top-list-item-link'}>
                            Admin
                        </Link>
                    </li>
                    <li className="dashboard_top-list-item">
                        <span  className={'dashboard_top-list-item-link'}>
                            <FiSettings  size={24}/>
                        </span>
                    </li>
                    <li className="dashboard_top-list-item">
                        <span onClick={()=>ctx.logOut()}  className={'dashboard_top-list-item-link'}>
                            <FiLogOut  size={24}/>
                        </span>
                    </li>
                </ul>
            </div>
            <div className="dashboard_content">

                <aside className="sidebar">

                    <ul className="sidebar-list">

                        <li className="sidebar-list-item">
                            <Link to="/blog/admin/" className="sidebar-list-item-link">
                                <div>
                                    <BiGridAlt className='d-icon-28' />
                                    <span>Dashboard</span>
                                </div>
                            </Link>
                        </li>

                        <li className="sidebar-list-item">
                            <Link to="/blog/add" className="sidebar-list-item-link">
                                <div>
                                    <BiPlus className='d-icon-28'/>
                                    <span>Create Post</span>
                                </div>
                            </Link>

                        </li>
                        <li className="sidebar-list-item">
                            <Link to="/blog/upload" className="sidebar-list-item-link">
                                <div>
                                    <BiVideo className='d-icon-28'/>
                                    <span>Upload</span>
                                </div>
                            </Link>
                        </li>
                        <li className="sidebar-list-item">
                            <Link to="/blog/allPosts" className="sidebar-list-item-link">
                                <div>
                                    <BiListUl className='d-icon-28'/>
                                    <span>All Posts</span>
                                </div>
                            </Link>
                        </li>
                        <li className="sidebar-list-item">
                            <Link to="/blog/allUsers" className="sidebar-list-item-link">
                                <div>
                                    <FiUsers className='d-icon-28'/>
                                    <span>All Users</span>
                                </div>
                            </Link>
                        </li>


                    </ul>
                </aside>
                <main className="dashboard_content-body">
                    {props.children}

                </main>

            </div>
        </div>
    );
};

export default AdminDash;