import React from 'react';
import useScrollToTop from "../helpers/UI/scrollToTop";
import logo  from "../assets/logo.svg"
import {Link} from "react-router-dom";
import fallingRice from '../assets/fallingRice.JPG'
import fallingRice2 from '../assets/fallRice.JPG'
import f1 from '../assets/IMG_7295.JPG'
import f2 from '../assets/IMG_7466.JPG'
import resulte40 from '../assets/resolute_logo.png'
import humanitySquare from '../assets/humanitysquare.JPG'
import {motion,useTransform, useViewportScroll} from "framer-motion";
const ObjPage = () => {
    const {scrollYProgress} = useViewportScroll();
    const scale = useTransform(scrollYProgress,[0,1],[1,1.1])
    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    useScrollToTop();
    return (
        <>
            <main className="obj">
                <div className="grid center-all pdT8">
                    <div className="grid-item2_4">
                        <div className="grid">
                            <motion.div className="grid-item2_4 objSm">

                                <motion.img initial={{opacity:0,scale:0.8}} animate={{opacity:1,scale:1}} transition={transition} src={fallingRice} alt={''} className={'fluidImg'}/>
                       <span className="objSmIconL">
                           <svg width="67" height="67" viewBox="0 0 67 67" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
<rect x="33.5" width="47.3762" height="47.3762" transform="rotate(45 33.5 0)" fill="#110C0C"/>
<rect x="33.5" y="11.8828" width="30.5714" height="30.5714" transform="rotate(45 33.5 11.8828)" fill="#79E76C"/>
</svg>
</span>
                            </motion.div>
                            <motion.div className="grid-item2_4 objSm textJust">

                                <motion.img initial={{opacity:0,scale:0.8}} animate={{opacity:1,scale:1}} transition={transition} src={fallingRice2} alt={''} className={'fluidImg'}/>
                       <span className="objSmIconR">
                           <svg width="67" height="67" viewBox="0 0 67 67" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
<rect x="33.5" width="47.3762" height="47.3762" transform="rotate(45 33.5 0)" fill="#110C0C"/>
<rect x="33.5" y="11.8828" width="30.5714" height="30.5714" transform="rotate(45 33.5 11.8828)" fill="#79E76C"/>
</svg>
</span>
                            </motion.div>
                        </div>
                        <motion.h6 style={{scale}} transition={transition} className="h6 mgT4">OUR OBJECTIVES</motion.h6>

                        <motion.h4 style={{scale}} transition={transition} className="h4 mgT2">The Future of Rice Farming in Africa</motion.h4>
                        <motion.p style={{scale}} transition={transition} className="h6 mgT4 ">
                            Our aim is to change the narrative of the African rice sector by producing, processing and exporting the highest quality rice that can easily compete with rice worldwide.
                        </motion.p>
                        <motion.p style={{scale}} transition={transition} className="h6 mgT4 ">
                            To reduce deforestation, we do not cut down trees on our farmlands, but we find ways to maximize productivity while allowing the trees to provide more O2 in the atmosphere.
                        </motion.p>
                        {/*<div className="mgT4">*/}
                        {/*    <span className="btn btn-dark">Read More</span>*/}
                        {/*</div>*/}
                    </div>
                    <div className="grid-item2_4 ">
                        <motion.div className="objLg mgL2Max" style={{scale}}>
                            <div className="objLgImg objLgItemTop">
                                <motion.img initial={{opacity:0,scale:0.8}} animate={{opacity:1,scale:1}} transition={transition} alt="" className="fluidImg" src={f1} />
                            </div>
                            <div className="objLgImg objLgItemBottom">
                                <motion.img initial={{opacity:0,scale:0.8}} animate={{opacity:1,scale:1}} transition={transition} alt="" className="fluidImg" src={f2}/>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </main>

            <div className="mgT12 partnersSection ">
                <div className="center-all pdV8">
                    <h5 className="h4"   style={{color: 'white',textAlign: "center"}}>Our Partners</h5>
                    <div className="grid mgT8">
                        <div className="grid-item1_4 partnersSectionCard" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <img src={resulte40} alt={'resolute 40'} title={'Resolute 40'}/>
                        </div>
                        <div className="grid-item1_4 partnersSectionCard">
                            <img src={humanitySquare} alt={'resolute 40'} className={'fluidImg'} title={'Resolute 40'}/>
                        </div>
                        {/*<div className="grid-item1_4 partnersSectionCard">*/}

                        {/*</div>*/}
                    </div>
                </div>
            </div>

            <footer id={'footer'}>
                <div className="center-all">
                    <div className="grid  pdV8">
                        <div className="grid-item2_4">

                            <img src={logo} alt="Kereksuk logo" className="logo"/>
                                <div className="grid mgT8 ">
                                    <Link to={'/#last'} className="h5 no-deco" style={{color: 'white'}}>About</Link>
                                    <Link to={'/faqs'} className="h5 no-deco" style={{color: 'white'}}>FAQs</Link>
                                    <Link to={'/blog'} className="h5 no-deco" style={{color: 'white'}}>Blog</Link>
                                    <Link to={'/yorfan'} className="h5 no-deco" style={{color: `var(--gold)`}}>YORFAN</Link>
                                </div>
                        </div>

                        <div className="grid-item2_4">
                            <div className="footer-reach">

                                <form>
 <span className="footer-reachTop">
                      <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="50.3728" height="50.3728" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 36.3812 72.0001)"
      fill="#79E76C"/>
<rect width="32.5051" height="32.5051" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 36.3812 59.3663)"
      fill="black"/>
</svg>
                       </span>
                                    <span className="footer-reachBottom"><svg width="72" height="72" viewBox="0 0 72 72"
                                                                              fill="none"
                                                                              xmlns="http://www.w3.org/2000/svg">
<rect width="50.3728" height="50.3728" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 36.3812 72.0001)"
      fill="#79E76C"/>
<rect width="32.5051" height="32.5051" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 36.3812 59.3663)"
      fill="black"/>
</svg>

                   </span>
                                    <div className="form-input-box">
                                        <input placeholder="Email" className="form-input"/>
                                    </div>
                                    <div className="form-input-box">
                                        <input placeholder="subject" className="form-input"/>
                                    </div>
                                          <div className="form-input-box">
                                        <textarea className="form-text" placeholder="description"/>
                                    </div>
                                    <button className="btn btn-primary mgT2">Reach Out</button>
                                </form>
                                <h6 className="h7 mgT4 whiteText">Kereksuk Live Stream.</h6>
                                <h6 className="h6 whiteText">Coming Soon</h6>
                            </div>

                        </div>
                    </div>
                    <div>
                        <h6 className="h6" style={{color: 'white'}}>Kereksuk &copy; 2022</h6>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default ObjPage;