import React, {useState} from 'react';
import {motion,AnimatePresence} from "framer-motion";

const FaqItem = ({title,body}) => {
    const [show,setShow] = useState(false);

    const list = {
        hidden:{
            opacity:0,y:100
        },
        show:{
            opacity: 1,y:0
        }
    }

    const item = {
        hidden:{
            y:20,opacity:0
        },
        visible:{
            y:0,
            opacity:1
        }
    }

    return (
        <motion.div variants={item} className={'faq-section-item'}>
            <div className="faq-section-item-top" onClick={()=>setShow(!show)}>

                <h6 className="h5 width80">{title}</h6>
                <input value={show}  id={'faq-section-item-top-icon'} type={'checkbox'}/>
                <label  className="faq-section-item-top-icon"><span >&nbsp;</span></label>
            </div>
            <AnimatePresence>

                {show?<motion.div    initial={"hidden"}
                                     animate={"show"}
                                     exit={{ opacity:0,y:100}}
                                     variants={list}>
                    <div className="faq-section-item-body">
                        <p className="h6">{body}</p>
                    </div>
                </motion.div>:null}
            </AnimatePresence>

        </motion.div>
    );
};

export default FaqItem;