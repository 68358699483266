import React, {useEffect, useState} from 'react';
import Logo from '../../assets/yorfanlogo.JPG'
import hero from '../../assets/yorfanhero.JPG'
import benefits from '../../assets/rice.JPEG'
import about from '../../assets/about_yorfan.JPG'
import '../../sass/pages/yorfan.scss'
import {BiBullseye} from "react-icons/bi";
import {FiEye} from "react-icons/fi";
import {motion,useViewportScroll,useTransform,useAnimation} from "framer-motion";
import {useInView} from 'react-intersection-observer';
import useScrollToTop from "../../helpers/UI/scrollToTop";
import yorf from '../../assets/yorfan-obj.png'
// import { Link as ScrollLink} from "react-scroll";
import Modal from "../../components/Modal";
const YorfanPage = () => {
    const controls = useAnimation();
    const [showModal,setShowModal] = useState();
     useScrollToTop();
    const { ref, inView } = useInView();
    const {scrollYProgress} = useViewportScroll();
    const scale= useTransform(scrollYProgress,[0,1],[0.8,1.1])
    const scaleBtn= useTransform(scrollYProgress,[0,1],[1,1.2])
    const transition = {
        duration:1,
        ease: [0.6,0.13,0.23,0.75]
    };

    useEffect(() => {
        console.log(inView)
        if (inView) {
            controls.start('visible').then(()=>{

                console.log('starting')
            });
        }
        if (!inView) {
            controls.start('hidden').then();
        }
    }, [controls, inView]);


    const container = {
        hidden:{
            opacity:1,scale:0,x:100
        },
        visible:{
            opacity:1,
            scale:1,
            x:0,
            transition:{
                delayChildren:0.3,
                staggerChildren:0.2
            }
        }
    }

    const item = {
        hidden:{
            y:20,opacity:0
        },
        visible:{
            y:0,
            opacity:1
        }
    }
    return (
        <>
            <header className={'yorfan-header'} style={{backgroundImage:`linear-gradient(rgba(0,0,0,0.4) 70%,rgba(255,255,255,.75) ),url(${hero})`}} >
                <motion.nav initial={{y:-100}} transition={transition} animate={{y:0}} className={'yorfan-nav'}>
                    <figure>
                        {/*<img src={Logo} alt={'YORFAN logo'} className={'logo'}/>*/}
                        <h1 className="h5 gold">YORFAN</h1>
                    </figure>
                    <motion.ul initial={'hidden'}

                               variants={container}
                               transition={{delay:1,...transition}}
                               animate={'visible'} className="yorfan-nav-list">
                    {/*<motion.li variants={item} className="yorfan-nav-list-item"><ScrollLink to={'about'}>About</ScrollLink></motion.li>*/}
                    <motion.li variants={item} className="yorfan-nav-list-item" onClick={()=>setShowModal(true)}>Reach Out</motion.li>
                </motion.ul>
                </motion.nav>
                <div className="yorfan-header-text">
                   <div className="grid">
                       <div className="grid-item2_4">
                           <h1 className="h3 whiteText">Young Rice Farmers Association of Nigeria</h1>
                            <p className="h6 mgT2 greyText">Creating Employment for Nigerian Youths</p>
                           <motion.button onClick={()=>setShowModal(true)} style={{scale:scaleBtn}} className="btn btn-dark mgT8">Reach Out</motion.button>
                       </div>
                       <div className="grid-item2_4">
                            <img alt={''} src={Logo} className={'fluidImg'}/>
                       </div>
                   </div>
                </div>


            </header>

            <section className={'goals mgT8'}>
                <div className="center-all">
                    <h5 className="h3 centerText">
                        Our Goals
                    </h5>
                    <p className="mgT1  centerText h6">Our Ideals simplified </p>
                    <motion.div className="mgT8 grid" style={{scale}}>
                        <div className="grid-item2_4 goalsCard">
                            <BiBullseye size={48} color={'#27821C'}/>
                                <h6 className="h6 mgT2">Mission</h6>
                            <div className="mgT2">
                                <p className="h7 greyText">Youth empowerment</p>
                                <p className="h7 greyText">Promote sustainable development in the agricultural sector
                                </p>
                            </div>


                        </div>
                        <div className="grid-item2_4 goalsCard">
                            <FiEye size={48} color={'#94A433'}/>
                                <h6 className="h6 mgT2">Vision</h6>
                            <div className="mgT2">
                                <p className="h7 greyText">Promote food security</p>
                                <p className="h7 greyText">Encourage youth engagement in agriculture</p>
                            </div>
                               </div>
                    </motion.div>
                </div>
            </section>
            <section className={'goals mgT8'}>
                <div className="center-all">

                    <motion.div className="mgT8 grid" style={{scale}}>
                        <div className="grid-item2_4 ">
                            <img alt={''} src={yorf} className={'fluidImg'}/>


                        </div>
                        <div className="grid-item2_4 ">
                            <h4 className={'h4'}>Our Objectives</h4>
                            <p className={'h6 mgT2'}>YORFANS primary goal is to create employment for recent graduates, NYSC members and the youth at large
                            </p>
                            <h4 className={'h4 mgT4'}>Methodology</h4>
                            <p className={'h6 mgT2'}>Our program comprises of an extensive 3-week online training course and a 1-week field course on rice farming
                            </p>
                        </div>
                    </motion.div>
                </div>
            </section>
            {
                showModal?<Modal closeModal={setShowModal}>
                    <h4 className="h4">Reach Out</h4>
                    <p className="h6 mgT1">Easily reach out to YORFAN Team here</p>
                    <form>
                        <div className="form-input-box">
                            <input className="form-input" placeholder={'Subject'}/>
                        </div>
                        <div className="form-input-box">
                            <input className="form-input" placeholder={'Email'}/>
                        </div>
                        <div className="form-input-box">
                            <textarea  style={{height:'10rem'}} className="form-textArea" placeholder={'description'}/>
                        </div>
                        <button className="btn btn-dark mgT2">Send</button>
                    </form>
                </Modal>:null
            }
            <div className="mgT12 yorfan_about mgB4" >
                <div className="center-all" >
                    <h3 className="h4 ">About Us</h3>
                    <p className="h6 mgT2 greyText width45">The Young Rice Farmers Association of Nigeria “YORFAN’ founded in 2021, was created with a core focus of passing knowledge on to the next generation.  Our mandate includes proffering solutions to challenges through inclusiveness in policy creation and promoting sustainable development in the agricultural sector</p>

                    <div className="grid- mgT4 " id={'about'}>
                      <div className="grid-item2_4 yorfan_about-item">

                              <img alt={'Rotimi Williams at Kereksuk Farms'} className={'fluidImg'} src={about}/>

                      </div>
                       <div className="grid-item2_4 mgL2 _mgT8">

                            <motion.div
                                         ref={ref}
                                           variants={container}
                                           animate={controls}
                            className={'centerText'}>
                                <motion.div variants={item} whileHover={{scale:1.5}} transition={{duration:.35,ease: [0.6,0.13,0.23,0.75]}}   className={'h1 bold'}>A</motion.div>
                                <motion.div variants={item}  whileHover={{scale:1.5}} transition={{duration:.35,ease: [0.6,0.13,0.23,0.75]}}  className={'h1 bold'}>B</motion.div>
                                <motion.div variants={item}  whileHover={{scale:1.5}} transition={{duration:.35,ease: [0.6,0.13,0.23,0.75]}}  className={'h1 bold'}>O</motion.div>
                                <motion.div variants={item}  whileHover={{scale:1.5}} transition={{duration:.35,ease: [0.6,0.13,0.23,0.75]}}  className={'h1 bold'}>U</motion.div>
                                <motion.div  variants={item}  whileHover={{scale:1.5}} transition={{duration:.35,ease: [0.6,0.13,0.23,0.75]}} className={'h1 bold'}>T</motion.div>
                            </motion.div>

                       </div>
                   </div>
                </div>
            </div>

          <Benefits/>
            <div className="yorfanfooter-section">
                <h6 className="h6 centerText whiteText">Yorfan.kereksuk&copy;2022</h6>
            </div>
            
        </>
    );
};

function Benefits() {
    const controls = useAnimation();
    const { ref, inView } = useInView();
    useEffect(() => {
        if (inView) {
            controls.start('visible').then(()=>{

                console.log('starting')
            });
        }
        if (!inView) {
            controls.start('hidden').then();
        }
    }, [controls, inView]);


    const container = {
        hidden:{
            opacity:1,scale:0,x:100
        },
        visible:{
            opacity:1,
            scale:1,
            x:0,
            transition:{
                delayChildren:0.3,
                staggerChildren:0.2
            }
        }
    }

    const item = {
        hidden:{
            y:20,opacity:0
        },
        visible:{
            y:0,
            opacity:1
        }
    }
    return (
        <section className="center-all mgB4 mgT12">
            <div>
                <h5 className="h4">Benefits of YORFAN</h5>
                <div className="grid mgT4">
                    <div className="grid-item2_4 greyCard">
                        <img className="fluidImg" src={benefits} alt={'kereksuk rice'}/>
                    </div>
                    <div className="grid-item2_4 mgT4">
                        <motion.div
                            style={{listStyle:'none',padding:'1rem',height:"100%"}}
                            ref={ref}
                            variants={container}
                            animate={controls} >
                            <motion.p variants={item} transition={{duration:.35,ease: [0.6,0.13,0.23,0.75]}}  className="h6 mgT2 greyText ">Access to start-up funds from the government and the Central Bank of Nigeria for participants who have successfully completed the program</motion.p>

                            <motion.p variants={item} transition={{duration:.35,ease: [0.6,0.13,0.23,0.75]}}  className="h6 mgT2 greyText ">An Agrotech incubator, easily accessible and readily available to any participant with ideas on how to improve the rice value chain .</motion.p>
                            <motion.p variants={item} transition={{duration:.35,ease: [0.6,0.13,0.23,0.75]}}  className="h6 mgT2 greyText ">Participants would have access to highly skilled agronomists in a case of crisis.

                            </motion.p>
                            <motion.p variants={item} transition={{duration:.35,ease: [0.6,0.13,0.23,0.75]}}  className="h6 mgT2 greyText ">Opportunity for mentorship and a monitoring and evaluation program by YORFAN for the participants.</motion.p>

                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );

}
export default YorfanPage;