import React,{useContext} from 'react';
import {UserProvider} from "./userProvider";
import {Navigate} from "react-router-dom";


const ProtectedRoute = ({children}) => {
    const user = useContext(UserProvider);
    return user.token?
       children:<Navigate to={'/'} replace/>
};
export default ProtectedRoute;