import React from 'react';
import '../sass/components/table.scss'
const Table = ({type,headers,body}) => {
    return (
        <div className='table-holder'>
             <table className={type}>
                 <thead>
                    <tr>{
                        headers.map((e,i)=>{
                            return (
                                <th key={i} >
                                    {e}
                                </th>
                            )
                        })
                    }</tr>
                 </thead>
                 <tbody>
                 {body?body:<tr ><td  colSpan={headers?.length?headers.length:4} className={'tr-nodata h6'}>NO Data Found</td></tr>}
                 </tbody>
             </table>
        </div>
    );
};

export default Table;