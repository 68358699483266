import React, { useState,useContext} from 'react';
import Logo from "../../../assets/logo.svg";
import {Link} from "react-router-dom";
import {BiShield} from "react-icons/bi";
import AxiosInstance from "../../../helpers/axiosInstance";
import {toast,ToastContainer} from "react-toastify";
import {UserProvider} from "../../../hoc/userProvider";
import Loader from "../../../hoc/loader";
import '../../../sass/pages/auth.scss'
const Login = () => {
    const user = useContext(UserProvider);
    const [loading,setLoading] = useState(false)
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    function login(e) {
        e.preventDefault();
        setLoading(true)
        const data = {
            email,
            password
        }

        if (handleValidate(email,'email') && handleValidate(password,'password')){
            AxiosInstance.post('/auth/login',data)
                .then((res)=>{
                    setLoading(false)
                    toast('Success');
                    console.log(res.data)
                    user.login(res.data.user,res.data.token)
                }).catch(err=>{

                setLoading(false)
                toast.error(err.response.data.msg)
            })
        }
        else{
            setLoading(false)
            toast.error('Invalid Information')
        }
    }

    function handleValidate(e,type) {
        switch (type){
            case 'email':

                return e.length>0;

            case 'password':

                return e.length>=8;
            default:
                break;
        }
    }
    return (
        <div className='authBox'  >
            <ToastContainer/>
            <div className="authBox-card">
                <figure className={'centerText'}>
                    <Link to={'/'}><img alt='piggy logo' src={Logo} className='logo' /></Link>
                </figure>
                <p className="h6 mgT2 greyText centerText">Secure Login <span className={'h5 greenText'}><BiShield/></span></p>
                <form onSubmit={login} className={'mgT4'}>
                    <label className="h8">Email</label>
                    <div className="form-input-box">
                        <input  value={email}  onChange={(e)=>{


                            setEmail(e.target.value)}} className={handleValidate(email,'email')?"form-input form-validate":"form-input form-validate-err"} placeholder={'Email Address'}/>
                    </div>
                    {handleValidate(email,'email')?null:<div><h6 className="h7 redText">Must be a valid Email</h6></div>}

                    <label className="h8">Password</label>
                    <div className="form-input-box">
                        <input  value={password} type={'password'}  onChange={(e)=>setPassword(e.target.value)} className={handleValidate(password,'password')?"form-input form-validate":"form-input form-validate-err"} placeholder={'Password'}/>
                    </div>
                    {handleValidate(password,'password')?null:<div><h6 className="h7 redText">Password must be 8 characters long, containing at least one upper case, a number and Special character (@#*$)</h6></div>}
                    {loading?<Loader/> :
                        <div className="mgT4">
                            <button className="btn btn-dark" style={{width:'100%'}}>Login</button>
                        </div>}
                    <div className="mgT2 centerText">
                        <p className="h7">Don't have an account?<Link to={'/register'} className={'link mgL1'}>Register</Link></p>
                        {/*<p className="h7 mgT1"><Link to={'/forgot'} className={'link'}>Forgot Password?</Link></p>*/}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;