import React, {useCallback, useEffect, useState} from 'react';
import Navigation from "../../../components/Navigation";
import AxiosInstance from "../../../helpers/axiosInstance";
import {useParams} from "react-router-dom";
import Moment from "react-moment";
import useScrollToTop from "../../../helpers/UI/scrollToTop";
import {motion} from "framer-motion";

const Article = () => {
    const params = useParams()
    useScrollToTop()
    const [post,setPost] = useState({});
    const getLatest = useCallback(()=>{
        AxiosInstance.get(`/art/p/${params.id}`)
            .then(res=>{
                console.log(res.data.data)
                setPost(res.data.data)
            })
            .catch(err=>{
                console.log(err)
            })
    },[setPost,params.id]);
    useEffect(()=>{
        getLatest()
    },[getLatest]);
    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    return (
        <motion.div initial={{opacity:0.2,y:-100}} animate={{opacity:1,y:0}} transition={transition}>
            <Navigation/>
            <div className="greyBox Gcenter-all mgT8">
                {
                    post.hasOwnProperty('title')?<>
                        <h4 className="h4">{post?.title}</h4>

                        <div className="img_box mgT4">
                            {
                                post.hasOwnProperty('image')?
                                    <img alt={post?.title} className={'fluidImg'} src={`https://kereksuk.fra1.digitaloceanspaces.com/${post?.image}`}/>
                                    :null
                            }
                        </div>
                        <div className="mgT2">
                            <span className="h6 greenText bold">{post?.owner?.fullName}</span> <span
                            className={'h7 mgL2 greyText'}><Moment format={'MMM DD, YYYY'}>{post.createdAt}</Moment></span>
                        </div>
                        <div className="mgT4 h6 textJust" dangerouslySetInnerHTML={{__html:post?.description}}/>
                    </>:null
                }
                {/*<div className="mgT8">*/}
                {/*    <h6 className="h5">Related Posts</h6>*/}
                {/*    <div className=" mgT4">*/}
                {/*        <div className="relatedArticle">*/}
                {/*            <h5 className="h5 greenText">Testing it again</h5>*/}

                {/*            <p className="h8 mgT2 whiteText">Rotimi Williams</p>*/}
                {/*            <p className="h7  whiteText">Aug 20,22</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className=" mgT4">*/}
                {/*        <div className="relatedArticle">*/}
                {/*            <h5 className="h5 greenText">Testing it again</h5>*/}

                {/*            <p className="h8 mgT2 whiteText">Rotimi Williams</p>*/}
                {/*            <p className="h7  whiteText">Aug 20,22</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>


        </motion.div>
    );
};

export default Article;