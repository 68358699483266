import React from 'react';
import {AiFillCloseCircle} from "react-icons/ai";
import {useOutsideAlerter} from "../helpers/UI/closepopups";
import '../sass/components/modal.scss';
import {motion} from "framer-motion";

const Modal = (props) => {

    const toggleRef = React.useRef(null);

        useOutsideAlerter(toggleRef, props.closeModal);

    return (
        <motion.div exit={{opacity:0,scale:10}} transition={{duration:1,ease:[0.23,0.15,0.26,0.93]}} className={`modal ${props.className}`}  >
            <div className="modal_top">
                <div onClick={()=>props.closeModal(false)} className="modal_top-close">
                    <AiFillCloseCircle/>
                </div>
            </div>
           <motion.div className={`modal_body ${props.body}`}  ref={toggleRef}>
               {props.children}
           </motion.div>
        </motion.div>
    );
};

export default Modal;